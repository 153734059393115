var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Rowhouse_list" },
    [
      _c("app-header", { attrs: { title: "办理排房", isShowBack: true } }),
      _c("div", { staticClass: "content-top" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.rowData.apartmentName) +
            _vm._s(_vm.rowData.buildName) +
            _vm._s(_vm.rowData.roomNo) +
            "房间\n    "
        ),
        _c("span", [_vm._v("/")]),
        _vm._v("\n    " + _vm._s(_vm.rowData.roomTypeStr) + "\n    "),
        _c("span", [_vm._v("/")]),
        _vm._v(
          "\n    " +
            _vm._s(["长租", "短租", "长短租"][_vm.rowData.rentalStyle]) +
            "\n    "
        ),
        _c("span", [_vm._v("/")]),
        _c("label", [
          _vm._v(
            _vm._s(_vm.rowData.rentalFee) +
              " " +
              _vm._s(_vm.rowData.rentalStyle == 0 ? "元/月" : "元/天")
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "content-box" },
        [
          _c(
            "el-form",
            {
              ref: "rowRoomForm",
              staticClass: "form-box",
              attrs: {
                inline: true,
                model: _vm.rowRoomForm,
                "label-width": "105px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: { label: "搜索房客", prop: "searchTenant" }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        remote: "",
                        clearable: "",
                        "reserve-keyword": "",
                        placeholder: "手机号",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.loading
                      },
                      on: {
                        change: function($event) {
                          _vm.selectTrigger(_vm.rowRoomForm.searchTenant)
                        }
                      },
                      model: {
                        value: _vm.rowRoomForm.searchTenant,
                        callback: function($$v) {
                          _vm.$set(_vm.rowRoomForm, "searchTenant", $$v)
                        },
                        expression: "rowRoomForm.searchTenant"
                      }
                    },
                    _vm._l(_vm.searchTenantOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "房客姓名", prop: "realname" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      disabled:
                        _vm.searchohabitantCertFlage ||
                        _vm.searchohabitantPhoneFlage
                    },
                    model: {
                      value: _vm.rowRoomForm.realname,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.rowRoomForm,
                          "realname",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "rowRoomForm.realname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "性别",
                    prop: "gender",
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "width70" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled:
                              _vm.searchohabitantCertFlage ||
                              _vm.searchohabitantPhoneFlage
                          },
                          model: {
                            value: _vm.rowRoomForm.gender,
                            callback: function($$v) {
                              _vm.$set(_vm.rowRoomForm, "gender", $$v)
                            },
                            expression: "rowRoomForm.gender"
                          }
                        },
                        _vm._l(_vm.genderList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否有证件",
                    prop: "haveCert",
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "width70" },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            disabled:
                              _vm.searchohabitantCertFlage ||
                              _vm.searchohabitantPhoneFlage ||
                              _vm.rowRoomForm.searchCertChangePhoneFlag ||
                              _vm.rowData.isKey == "1"
                          },
                          on: { change: _vm.haveCertChange },
                          model: {
                            value: _vm.rowRoomForm.haveCert,
                            callback: function($$v) {
                              _vm.$set(_vm.rowRoomForm, "haveCert", $$v)
                            },
                            expression: "rowRoomForm.haveCert"
                          }
                        },
                        _vm._l(_vm.haveCertList, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm.rowRoomForm.haveCert == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "证件类型", prop: "certType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: "请选择",
                            clearable: "",
                            disabled:
                              _vm.searchohabitantPhoneFlage ||
                              _vm.searchohabitantCertFlage ||
                              _vm.rowData.isKey == "1"
                          },
                          on: {
                            change: function($event) {
                              _vm.certTypeChange(_vm.rowRoomForm.certType)
                            }
                          },
                          model: {
                            value: _vm.rowRoomForm.certType,
                            callback: function($$v) {
                              _vm.$set(_vm.rowRoomForm, "certType", $$v)
                            },
                            expression: "rowRoomForm.certType"
                          }
                        },
                        _vm._l(_vm.certTypeOptions, function(item) {
                          return _c("el-option", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  item.value != "7" ||
                                  (_vm.rowData.apartmentCode &&
                                    _vm.rowData.apartmentType == "7" &&
                                    item.value == "7"),
                                expression:
                                  "item.value!='7' || (rowData.apartmentCode && rowData.apartmentType=='7' && item.value=='7')"
                              }
                            ],
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rowRoomForm.haveCert == "1"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "420px" },
                      attrs: {
                        label:
                          _vm.rowRoomForm.certType == "7"
                            ? "学号/职工号"
                            : "证件号码",
                        prop: "certNum"
                      }
                    },
                    [
                      _c("el-input", {
                        staticClass: "lt-select fl",
                        attrs: {
                          clearable: "",
                          disabled: _vm.searchohabitantPhoneFlage,
                          placeholder:
                            _vm.rowRoomForm.certType == "7"
                              ? "学号/职工号"
                              : "证件号码",
                          maxlength: "24"
                        },
                        on: {
                          input: function($event) {
                            _vm.searchCertNumTenant(_vm.rowRoomForm, "1")
                          }
                        },
                        model: {
                          value: _vm.rowRoomForm.certNum,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowRoomForm,
                              "certNum",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowRoomForm.certNum"
                        }
                      }),
                      _c(
                        "el-button",
                        {
                          staticClass: "rt-btn",
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.cardReadingBtn("1")
                            }
                          }
                        },
                        [_vm._v("读身份证")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rowRoomForm.haveCert == "1" ? _c("div") : _vm._e(),
              _vm.rowData.isUploadPic == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上传证件照片", prop: "cardFrontPic" } },
                    [
                      _c("div", { staticClass: "picCont" }, [
                        _c(
                          "div",
                          { staticClass: "pic-lt" },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                class: {
                                  "one-upload-disabled":
                                    _vm.rowRoomForm.cardFrontPic &&
                                    _vm.rowRoomForm.cardFrontPic.length > 0
                                },
                                attrs: {
                                  accept: "image/*",
                                  "list-type": "picture-card",
                                  action:
                                    _vm.UPLOAD_URL +
                                    "/user-service/weChat/uploadPic",
                                  "file-list": _vm.rowRoomForm.cardFrontPic,
                                  "on-preview": _vm.onPreviewByUploadImg,
                                  "before-upload": _vm.onBeforeUpload,
                                  "before-remove":
                                    _vm.onBeforeRemoveByUploadImg,
                                  "on-success": _vm.onSuccessByUploadImgOnly(
                                    "cardFrontPic"
                                  ),
                                  "on-remove": _vm.onHandleRemove(
                                    "cardFrontPic"
                                  ),
                                  "on-exceed": _vm.onExceed
                                }
                              },
                              [
                                _vm.rowRoomForm.cardFrontPic &&
                                _vm.rowRoomForm.cardFrontPic.length == 0
                                  ? _c("i", { staticClass: "el-icon-plus" })
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.rowRoomForm.haveCert == "1"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否有手机号码",
                        prop: "havePhone",
                        "label-width": "120px"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "width70" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled:
                                  _vm.searchohabitantCertFlage ||
                                  _vm.searchohabitantPhoneFlage ||
                                  _vm.rowRoomForm.searchCertChangePhoneFlag
                              },
                              on: { change: _vm.havePhoneChange },
                              model: {
                                value: _vm.rowRoomForm.havePhone,
                                callback: function($$v) {
                                  _vm.$set(_vm.rowRoomForm, "havePhone", $$v)
                                },
                                expression: "rowRoomForm.havePhone"
                              }
                            },
                            _vm._l(_vm.havePhoneList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.rowRoomForm.havePhone == "1" ||
              _vm.rowRoomForm.haveCert == "0"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "房客电话", prop: "realphone" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "135px" },
                        attrs: {
                          placeholder: "房客电话",
                          clearable: "",
                          disabled: _vm.searchohabitantCertFlage,
                          maxlength: "11"
                        },
                        on: {
                          input: function($event) {
                            _vm.searchRealphoneTenant(_vm.rowRoomForm.realphone)
                          }
                        },
                        model: {
                          value: _vm.rowRoomForm.realphone,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowRoomForm,
                              "realphone",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowRoomForm.realphone"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rowRoomForm.havePhone == "0" &&
              _vm.rowRoomForm.haveCert == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "登录密码", prop: "passWord" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "135px" },
                        attrs: {
                          type: _vm.rowRoomForm.passWordType,
                          placeholder: "登录密码",
                          clearable: "",
                          maxlength: "12"
                        },
                        on: { focus: _vm.passWordFocus },
                        model: {
                          value: _vm.rowRoomForm.passWord,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowRoomForm,
                              "passWord",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowRoomForm.passWord"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rowData.rentalStyle == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "租赁模式", prop: "rentalStyle" } },
                    [
                      _c(
                        "div",
                        { staticClass: "width90" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.changeSelect },
                              model: {
                                value: _vm.rowRoomForm.rentalStyle,
                                callback: function($$v) {
                                  _vm.$set(_vm.rowRoomForm, "rentalStyle", $$v)
                                },
                                expression: "rowRoomForm.rentalStyle"
                              }
                            },
                            _vm._l(_vm.rentalStyleList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm.rowData.rentalStyle == 0 || _vm.LeasingModel == "0"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "租期", prop: "leaseTerm" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.rowRoomForm.leaseTerm,
                            callback: function($$v) {
                              _vm.$set(_vm.rowRoomForm, "leaseTerm", $$v)
                            },
                            expression: "rowRoomForm.leaseTerm"
                          }
                        },
                        _vm._l(_vm.monthOption, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm.rowData.rentalStyle == 1 || _vm.LeasingModel == "1"
                  ? _c(
                      "el-form-item",
                      {
                        staticStyle: { width: "710px" },
                        attrs: { label: "租期", prop: "sortleaseTerm" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "width70" },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "daterange",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "value-format": "yyyy-MM-dd",
                                "picker-options": _vm.pickerOptions
                              },
                              model: {
                                value: _vm.rowRoomForm.sortleaseTerm,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.rowRoomForm,
                                    "sortleaseTerm",
                                    $$v
                                  )
                                },
                                expression: "rowRoomForm.sortleaseTerm"
                              }
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "margin-left": "10px",
                                  color: "#999999"
                                }
                              },
                              [_vm._v("开始日期的14:00:00至结束日期的14:00:00")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
              _vm.rowData.apartmentType == "7" &&
              _vm.rowRoomForm.certType !== "7"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "学号/职工号", prop: "studentCode" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.rowRoomForm.studentCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.rowRoomForm,
                              "studentCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "rowRoomForm.studentCode"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "dashed-box" }),
              _c(
                "div",
                { staticClass: "list-box" },
                _vm._l(_vm.cohabitantList, function(item, index) {
                  return _c("div", { key: index, staticClass: "list-item" }, [
                    _c(
                      "div",
                      { staticClass: "list-col" },
                      [
                        _c("label", [
                          _vm._v(_vm._s(index + 1) + "、搜索同住人")
                        ]),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              remote: "",
                              "reserve-keyword": "",
                              placeholder: "手机号",
                              "remote-method": _vm.remoteMethodCohabitant,
                              loading: _vm.loading,
                              clearable: ""
                            },
                            on: {
                              change: function($event) {
                                _vm.selectCohabitant(
                                  item.searchCohabitant,
                                  index
                                )
                              }
                            },
                            model: {
                              value: item.searchCohabitant,
                              callback: function($$v) {
                                _vm.$set(item, "searchCohabitant", $$v)
                              },
                              expression: "item.searchCohabitant"
                            }
                          },
                          _vm._l(_vm.searchCohabitantOptions, function(item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "list-col" },
                      [
                        _c("label", [_vm._v("同住人姓名")]),
                        _c("el-input", {
                          staticClass: "el-name",
                          attrs: {
                            disabled: item.searchohabitantFlage,
                            placeholder: "请输入同住人姓名"
                          },
                          model: {
                            value: item.cohabitantName,
                            callback: function($$v) {
                              _vm.$set(item, "cohabitantName", $$v)
                            },
                            expression: "item.cohabitantName"
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "list-col" }, [
                      _c("label", [_vm._v("性别")]),
                      _c(
                        "div",
                        { staticClass: "width100" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled: item.searchohabitantFlage
                              },
                              model: {
                                value: item.cohabitantGender,
                                callback: function($$v) {
                                  _vm.$set(item, "cohabitantGender", $$v)
                                },
                                expression: "item.cohabitantGender"
                              }
                            },
                            _vm._l(_vm.genderList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "list-col" }, [
                      _c("label", [_vm._v("是否有证件")]),
                      _c(
                        "div",
                        { staticClass: "width100" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择",
                                disabled:
                                  item.searchohabitantFlage ||
                                  item.searchCertChangePhoneFlag ||
                                  _vm.rowData.isKey == "1"
                              },
                              on: {
                                change: function($event) {
                                  _vm.cohabitantHaveCertChange(index)
                                }
                              },
                              model: {
                                value: item.haveCert,
                                callback: function($$v) {
                                  _vm.$set(item, "haveCert", $$v)
                                },
                                expression: "item.haveCert"
                              }
                            },
                            _vm._l(_vm.haveCertList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    item.haveCert == "1"
                      ? _c(
                          "div",
                          { staticClass: "list-col" },
                          [
                            _c("label", [_vm._v("证件类型")]),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  disabled:
                                    item.searchohabitantFlage ||
                                    _vm.rowData.isKey == "1",
                                  placeholder: "请选择证件类型",
                                  clearable: ""
                                },
                                model: {
                                  value: item.cohabitantType,
                                  callback: function($$v) {
                                    _vm.$set(item, "cohabitantType", $$v)
                                  },
                                  expression: "item.cohabitantType"
                                }
                              },
                              _vm._l(_vm.certTypeOptions, function(item) {
                                return _c("el-option", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        item.value != "7" ||
                                        (_vm.rowData.apartmentCode &&
                                          _vm.rowData.apartmentType == "7" &&
                                          item.value == "7"),
                                      expression:
                                        "item.value!='7' || (rowData.apartmentCode && rowData.apartmentType == '7' && item.value=='7')"
                                    }
                                  ],
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    item.haveCert == "1"
                      ? _c(
                          "div",
                          { staticClass: "list-col" },
                          [
                            _c("label", [
                              _vm._v(
                                _vm._s(
                                  item.cohabitantType == "7"
                                    ? "学号/职工号"
                                    : "证件号码"
                                )
                              )
                            ]),
                            _c("el-input", {
                              attrs: {
                                placeholder:
                                  item.cohabitantType == "7"
                                    ? "学号/职工号"
                                    : "证件号码",
                                clearable: "",
                                disabled: item.searchohabitantPhoneFlage,
                                maxlength: "24"
                              },
                              on: {
                                input: function($event) {
                                  _vm.searchCertNumCohabitant(item, index, "1")
                                }
                              },
                              model: {
                                value: item.cohabitantCert,
                                callback: function($$v) {
                                  _vm.$set(
                                    item,
                                    "cohabitantCert",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.cohabitantCert"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    item.haveCert == "1"
                      ? _c(
                          "div",
                          { staticClass: "list-col" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "el-btn",
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.cardReadingBtn("2", index)
                                  }
                                }
                              },
                              [_vm._v("读身份证")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.rowData.isUploadPic == "1"
                      ? _c(
                          "div",
                          { staticClass: "list-col" },
                          [
                            _c("label", [_vm._v("上传证件照片")]),
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: {
                                placeholder: "点击上传按钮上传",
                                clearable: "",
                                disabled: ""
                              },
                              model: {
                                value: item.cardText,
                                callback: function($$v) {
                                  _vm.$set(
                                    item,
                                    "cardText",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.cardText"
                              }
                            }),
                            _c(
                              "el-button",
                              {
                                staticClass: "el-btn",
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.uploadCardBtn(index)
                                  }
                                }
                              },
                              [_vm._v("上传")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    item.haveCert == "1"
                      ? _c("div", { staticClass: "list-col" }, [
                          _c("label", [_vm._v("是否有手机号码")]),
                          _c(
                            "div",
                            { staticClass: "width100" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择",
                                    disabled:
                                      item.searchohabitantFlage ||
                                      item.searchCertChangePhoneFlag
                                  },
                                  on: {
                                    change: function($event) {
                                      _vm.cohabitantHavePhoneChange(index)
                                    }
                                  },
                                  model: {
                                    value: item.havePhone,
                                    callback: function($$v) {
                                      _vm.$set(item, "havePhone", $$v)
                                    },
                                    expression: "item.havePhone"
                                  }
                                },
                                _vm._l(_vm.havePhoneList, function(item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e(),
                    item.havePhone == "1" || item.haveCert == "0"
                      ? _c(
                          "div",
                          { staticClass: "list-col" },
                          [
                            _c("label", [_vm._v("电话")]),
                            _c("el-input", {
                              attrs: {
                                placeholder: "同住人电话",
                                clearable: "",
                                disabled: item.searchohabitantCertFlage,
                                maxlength: "11"
                              },
                              on: {
                                input: function($event) {
                                  _vm.searchRealphoneCohabitant(
                                    item.cohabitantPhone,
                                    index
                                  )
                                }
                              },
                              model: {
                                value: item.cohabitantPhone,
                                callback: function($$v) {
                                  _vm.$set(
                                    item,
                                    "cohabitantPhone",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.cohabitantPhone"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.rowData.apartmentType == "7" &&
                    item.cohabitantType !== "7"
                      ? _c(
                          "div",
                          { staticClass: "list-col" },
                          [
                            _c("label", [_vm._v("学号/职工号")]),
                            _c("el-input", {
                              staticClass: "el-name",
                              model: {
                                value: item.studentCode,
                                callback: function($$v) {
                                  _vm.$set(item, "studentCode", $$v)
                                },
                                expression: "item.studentCode"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    item.havePhone == "0" && item.haveCert == "1"
                      ? _c(
                          "div",
                          { staticClass: "list-col" },
                          [
                            _c("label", [_vm._v("登录密码")]),
                            _c("el-input", {
                              attrs: {
                                type: item.passWordType,
                                placeholder: "登录密码",
                                maxlength: "12",
                                clearable: ""
                              },
                              on: {
                                focus: function($event) {
                                  _vm.passWordItemFocus(index)
                                }
                              },
                              model: {
                                value: item.passWord,
                                callback: function($$v) {
                                  _vm.$set(
                                    item,
                                    "passWord",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "item.passWord"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "list-buts" },
                      [
                        _c("el-button", {
                          staticClass: "list-but",
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: {
                            click: function($event) {
                              _vm.addItem(index)
                            }
                          }
                        }),
                        _c("el-button", {
                          staticClass: "list-but",
                          attrs: { type: "danger", icon: "el-icon-minus" },
                          on: {
                            click: function($event) {
                              _vm.delItem(index)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "bottom-box" },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "bottom_boxBtn",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.submitBtn("rowRoomForm")
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传证件照片",
            visible: _vm.dialogUplodCardShow,
            width: "630px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogUplodCardShow = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "cohabitantList[curRoommateIndex]",
              staticClass: "form-box",
              attrs: {
                inline: true,
                model: _vm.cohabitantList[_vm.curRoommateIndex],
                "label-width": "100px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "证件照片" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      class: {
                        "one-upload-disabled":
                          _vm.cohabitantList[_vm.curRoommateIndex]
                            .cardFrontPic &&
                          _vm.cohabitantList[_vm.curRoommateIndex].cardFrontPic
                            .length > 0
                      },
                      attrs: {
                        accept: "image/*",
                        "list-type": "picture-card",
                        action:
                          _vm.UPLOAD_URL + "/user-service/weChat/uploadPic",
                        "file-list":
                          _vm.cohabitantList[_vm.curRoommateIndex].cardFrontPic,
                        "on-preview": _vm.onPreviewByUploadImg,
                        "before-upload": _vm.onBeforeUpload,
                        "before-remove": _vm.onBeforeRemoveByUploadImg,
                        "on-success": _vm.onSuccessByUploadImgOnly(
                          "cardFrontPic",
                          _vm.curRoommateIndex
                        ),
                        "on-remove": _vm.onHandleRemove(
                          "cardFrontPic",
                          _vm.curRoommateIndex
                        ),
                        "on-exceed": _vm.onExceed
                      }
                    },
                    [
                      _vm.cohabitantList[_vm.curRoommateIndex].cardFrontPic &&
                      _vm.cohabitantList[_vm.curRoommateIndex].cardFrontPic
                        .length == 0
                        ? _c("i", { staticClass: "el-icon-plus" })
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-box" },
            [
              _c(
                "el-button",
                {
                  staticClass: "bottom_boxBtn",
                  attrs: { type: "primary" },
                  on: { click: _vm.dialogBtn }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogImgVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogImgVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }